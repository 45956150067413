import * as React from 'react';

interface IOnboardHeaderGroupProps {
  heading: string;
  subHeading: string | React.ReactNode;
}

export function OnboardHeaderGroup({ heading, subHeading }: IOnboardHeaderGroupProps) {
  return (
    <div className='flex w-full max-w-xl flex-col gap-4'>
      <h1 className='text-[32px] font-medium leading-8 tracking-tighter'>{heading}</h1>
      <h2 className='text-base font-normal text-gray-500'>{subHeading}</h2>
    </div>
  );
}
